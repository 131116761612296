<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="hero-content">
					<span class="hero-title">
						{{ defaults[locale].homepage.headingTitle }}
					</span>
					<span class="hero-subtitle">
						{{ defaults[locale].homepage.headingSubTitle }}
					</span>
				</div>
			</template>
		</heading-image>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:introduction-content="page.introductionContent"
		>
			<template #content>
				<div class="columns column6">
					<div class="title-reverse-wrapper">
						<h1>{{ page.title || page.header }}</h1>
						<h2 class="subtitle" :class="!page.subtitle && 'no-after'">{{ page.subtitle }}</h2>
					</div>
					<div v-parse-links v-html="page.content" />
				</div>
				<div class="columns column6">
					<picture v-if="defaults[locale].homepage.indexIntroImage">
						<source
							v-if="defaults[locale].homepage.indexIntroImageWebp"
							:srcset="defaults[locale].homepage.indexIntroImageWebp"
							type="image/webp"
						/>
						<source :srcset="defaults[locale].homepage.indexIntroImage" />
						<img
							loading="lazy"
							:src="defaults[locale].homepage.indexIntroImage"
							:alt="defaults[locale].homepage.indexIntroImageAlt"
						/>
					</picture>
					<div
						v-if="page.introductionContent"
						v-parse-links
						class="intro-content"
						v-html="page.introductionContent"
					/>
				</div>
			</template>
		</main-content>

		<booking-benefits
			:title="defaults[locale].homepage.sectionBenefitsTitle"
			:subtitle="defaults[locale].homepage.sectionBenefitsSubtitle"
			:benefits="defaults[locale].homepage.bookingBenefits"
			:book-button="defaults[locale].website.book"
			:image="{
				image: defaults[locale].homepage.sectionBenefitsImage,
				imageAlt: defaults[locale].homepage.sectionBenefitsImageAlt,
				imageWebp: defaults[locale].homepage.sectionBenefitsImageWebp,
			}"
		/>

		<rooms-promotions
			:items="accommodations"
			:header="defaults[locale].accommodations.sectionRoomsTitle"
			:subtitle="defaults[locale].accommodations.subtitle"
			:content="defaults[locale].accommodations.sectionRoomsContent"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<promoblocks
			v-if="page && promoblocksData.length"
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="4"
		/>

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>

		<news-overview :news="news" />
		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: accommodations } = await useWebsiteFetch('accommodations', {
	query: { language: locale.value },
	key: `${locale.value}/accommodations`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 47%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.4s ease;
}

.hero-content span {
	color: #fff;
	font-weight: 400;
	font-size: 48px;
	letter-spacing: 1px;
	width: 100%;
	font-family: var(--heading-font-family);
	display: inline-block;
	margin: 0 0 10px;
}

.hero-content .hero-subtitle {
	font-size: 24px;
	font-weight: 300;
	font-family: var(--body-font-family);
}

.intro-content {
	max-width: 520px;
	margin: 40px 0 0;
}

@media (max-width: 880px) {
	.hero-content {
		.hero-title {
			font-size: 40px;
		}

		.hero-subtitle {
			font-size: 22px;
		}
	}
}

@media (max-width: 680px) {
	.hero-content {
		top: auto;
		bottom: 20%;

		.hero-title {
			font-size: 36px;
		}

		.hero-subtitle {
			font-size: 20px;
		}
	}
}

@media (max-width: 520px) {
	.hero-content {
		.hero-title {
			font-size: 32px;
		}

		.hero-subtitle {
			font-size: 18px;
		}
	}
}
</style>
