<template>
	<section class="main-content news-section">
		<div class="row row-header align-center">
			<h2 class="subtitle">{{ defaults[locale].homepage.sectionNewsTitle }}</h2>
			<div v-parse-links v-html="defaults[locale].homepage.sectionNewsContent" />
		</div>
		<div class="row flex-row">
			<div class="flex-row column6">
				<div v-for="item in news.slice(0, 1)" :key="item.ID" class="block-item white">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="button-arrow">
								{{ $t('readMore') }}
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
			<div class="flex-row column6">
				<div v-for="item in news.slice(1, 5)" :key="item.ID" class="block-item white">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="button-arrow">
								{{ $t('readMore') }}
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	news: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.event-date {
	color: #fff;
	font-size: 14px;
	margin: 0 0 10px;
	display: inline-block;
	width: 100%;
	letter-spacing: 2px;
}

.block-item {
	p,
	:deep(p) {
		color: #fff;
	}

	.button-arrow {
		color: #fff;
		border-color: #fff;
		background: url('~/assets/images/icon-button-arrow-white.png') no-repeat center right 25px;

		&:hover {
			background: url('~/assets/images/icon-button-arrow-white.png') no-repeat center right 20px
				var(--cta-color-hover);
		}

		svg {
			color: #fff;
		}
	}
}
</style>
